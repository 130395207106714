import {
  Box,
  Collapse,
  Divider,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

import greenCheckIcon from "../images/v3/icons/GreenCheckIcon.webp";
import smeImg from "../images/v2/psg/IMDA_SME_Large.webp";
import preApprovedAgent from "../images/v3/singaporeCrmHomepage/preApprovedAgent.webp";
import triangleArrow from "../images/v3/icons/triangleArrow.webp";
import autoCaptureLeadsIcon from "../images/v3/icons/autoCaptureLeadsIcon.webp";
import airCallIcon from "../images/v3/icons/airCallIcon.webp";
import aiChatIcon from "../images/v3/icons/aiChatIcon.webp";
import dashboardIcon from "../images/v3/icons/dashboardIcon.webp";
import messageIcon from "../images/v3/icons/messageIcon.webp";
import mobileIcon from "../images/v3/icons/mobileIcon.webp";
import greenCheckOutline from "../images/v3/icons/greenCheckOutline.webp";
import omniChannelIcon from "../images/v3/icons/omniChannelIcon.webp";
import oppertunityIcon from "../images/v3/icons/oppertunityIcon.webp";
import quotationIcon from "../images/v3/icons/quotationIcon.webp";
import salesIcon from "../images/v3/icons/salesIcon.webp";
import utmTrackingIcon from "../images/v3/icons/utmTrackingIcon.webp";
import videoCallIcon from "../images/v3/icons/videoCallIcon.webp";
import whatsAppFlowIcon from "../images/v3/icons/whatsAppFlowIcon.webp";
import piplineIcon from "../images/v3/icons/piplineIcon.webp";
import whatsappIcon from "../images/v3/icons/whatsappIcon.webp";
import contactmanagementIcon from "../images/v3/icons/contactManagementIcon.webp";
import whatsappBusinessPartner from "../images/v3/singaporeCrmHomepage/whatsappBusinessPartner.webp";
import salesPiplineImage from "../images/v3/singaporeCrmHomepage/salesPiplineImage.webp";
import pcHomepage from "../images/v3/singaporeCrmHomepage/pcHomepage.png";
import sageCrmImage from "../images/v3/singaporeCrmHomepage/sageCrmImage.webp";
import tigernixCrmImage from "../images/v3/singaporeCrmHomepage/tigernixCrmImage.webp";
import flavorCrmImage from "../images/v3/singaporeCrmHomepage/flavorCrmImage.webp";
import starCrmImage from "../images/v3/singaporeCrmHomepage/starCrmImage.webp";
import supportImage from "../images/v3/singaporeCrmHomepage/supportImage.webp";
import sectionBackground from "../images/v3/singaporeCrmHomepage/sectionBackground.webp";
import integrationChannels from "../images/v3/singaporeCrmHomepage/integrationChannels.webp";
import metaBusinessPartner from "../images/v3/singaporeCrmHomepage/metaBusinessPartner.webp";
import singaporeCrmHeroImg from "../images/v3/singaporeCrmHomepage/singaporeCrmHeroImg.webp";
import benifitImage1 from "../images/v3/singaporeCrmHomepage/benifitImage1.webp";
import benifitImage2 from "../images/v3/singaporeCrmHomepage/benifitImage2.webp";
import benifitImage3 from "../images/v3/singaporeCrmHomepage/benifitImage3.webp";
import benifitImage4 from "../images/v3/singaporeCrmHomepage/benifitImage4.webp";
import beautyImage from "../images/v3/singaporeCrmHomepage/beautyImage.webp";
import travelImage from "../images/v3/singaporeCrmHomepage/travelImage.webp";
import foodImage from "../images/v3/singaporeCrmHomepage/foodImage.webp";
import integratesWithMultipleApp from "../images/v3/singaporeCrmHomepage/integratesWithMultipleApp.webp";
import clsx from "clsx";
import SEO from "../components/SEO";
import CompanyCard from "../components/v2/CompanyCard";
// import CompanyCarousel from "../components/v2/CompanyCarousel";

const useStyles = makeStyles((theme) => ({
  singaporeHomeBanner: {
    backgroundImage:
      "linear-gradient(57deg, rgba(2, 145, 174, 0.2) 18%,  rgba(255, 255, 255, 0.2) 65%)",
  },
  heroSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingBottom: "56px",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      padding: 0,
    },
  },
  rightSection: {
    width: "40%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  leftSection: {
    width: "50%",
    flexDirection: "column",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "30px",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  img: {
    width: "100%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      position: "relative",
      top: "30px",
    },
  },
  highlight: {
    color: "#f95c3d",
  },
  list: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "100%",
    gap: "25px",
    "@media only screen and (max-width: 600px)": {
      gap: "15px",
      // marginTop: "20px",
    },
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "47%",
    gap: "18px",
    "@media only screen and (max-width: 600px)": {
      gap: "15px",
    },
  },
  listItemIcon: {
    color: "#fff",
    padding: "5px",
    fontSize: "10px",
    marginRight: "16px",
    borderRadius: "50%",
    backgroundColor: "#08a742",
  },
  listItemText: {
    fontSize: "",
  },
  smeSection: {
    "& .container": {
      paddingTop: "100px",
      "@media only screen and (max-width: 600px)": {
        paddingTop: "60px",
      },
    },
  },
  benifitSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    gap: "50px",
    marginTop: "60px",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column-reverse",
    },
  },
  benifitSectionLeft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "50%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  benifitSectionRight: {
    width: "40%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  benifitImage: {
    width: "100%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },

  contentBoxDetails: {
    background: "transparent",
    borderRadius: "5px",
    padding: "14px",
    display: "flex",
    alignItems: "baseline",
    justifyContent: "flex-start",
    gap: "20px",
    position: "relative",
    transition: "0.3s ease",
    "& img": {
      visibility: "hidden",
      opacity: 0,
      transition: "0.3s ease",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      left: 0,
      height: "85%",
      top: "7%",
      width: "2px",
      visibility: "hidden",
      opacity: 0,
      transition: "0.3s ease",
    },
    "&.active": {
      "&::before": {
        visibility: "visible",
        opacity: 1,
      },
      "& img": {
        visibility: "visible",
        opacity: 1,
      },
    },
    "&.benefit-1.active": {
      background: "#E3F8E4",
      "&::before": {
        background: "#4CAF50",
      },
    },
    "&.benefit-2.active": {
      background: "#F6F3CF",
      "&::before": {
        background: "#FFC107",
      },
    },
    "&.benefit-3.active": {
      background: "#E1E6FC",
      "&::before": {
        background: "#3F51B5",
      },
    },
    "&.benefit-4.active": {
      background: "#DAEEF1",
      "&::before": {
        background: "#00BCD4",
      },
    },
    "&.benefit-5.active": {
      background: "#fcd9e4",
      "&::before": {
        background: "#ce557a",
      },
    },
    "@media only screen and (max-width: 600px)": {
      padding: "10px",
      gap: "10px",
    },
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "16px",
    width: "100%",
    cursor: "pointer",
  },
  contentDetails: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    // gap: "20px",
    flexDirection: "column",
  },
  cardsContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginTop: "35px",
    rowGap: "15px",
  },
  card: {
    width: "24%",
    backgroundImage: "linear-gradient(to bottom, #d5f1ff, #dcfdff);",
    padding: "15px 20px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "20px",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  cardImage: {
    maxWidth: "26px",
    width: "auto",
  },
  highlightSection: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginTop: "50px",
    rowGap: "20px",
  },
  highlightCard: {
    width: "49%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "30px",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  salesPipelineImageContainer: {
    width: "70%",
    margin: "60px auto 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  salesPipeline: {
    width: "100%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  statsContainer: {
    marginTop: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      flexDirection: "column",
      gap: "15px",
    },
  },
  statsBox: {
    display: "flex",
    gap: "15px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRight: "1px solid black",
    width: "29%",
    "&:last-child": {
      borderRight: "none",
    },
    "@media only screen and (max-width: 600px)": {
      width: "70%",
      borderRight: "none",
      paddingBottom: "15px",
      borderBottom: "1px solid black",
      "&:last-child": {
        paddingBottom: "0",
        borderBottom: "none",
      },
    },
  },
  supportSystem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "30px",
  },
  supportToggler: {
    marginTop: "30px",
    display: "flex",
    alignItems: "center",
    gap: "30px",
    justifyContent: "flex-start",
    "@media only screen and (max-width: 600px)": {
      display: "none",
      width: "100%",
      gap: "10px",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  },
  toggler: {
    fontSize: "22px",
    padding: "15px",
    borderRadius: "100px",
    border: "1px solid #00b4d3",
    cursor: "pointer",
    transition: "0.3s ease",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#00b4d3",
      color: "white",
    },
    "&.active": {
      backgroundColor: "#00b4d3",
      color: "white",
    },
    "@media only screen and (max-width: 600px)": {
      minWidth: "max-content",
      padding: "10px",
      fontSize: "15px",
    },
  },
  mobileToggler: {
    fontSize: "15px",
    padding: "10px",
    borderRadius: "100px",
    border: "1px solid #00b4d3",
    cursor: "pointer",
    textAlign: "center",
    transition: "0.3s ease",
    width: "100%",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#00b4d3",
      color: "white",
    },
    "&.active": {
      backgroundColor: "#00b4d3",
      color: "white",
    },
  },
  supportSystemMobileContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "20px",
    width: "100%",
  },

  supportSystemContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "50px",
    marginTop: "30px",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      justifyContent: "flex-start",
      gap: "20px",
      width: "100%",
    },
  },
  supportList: {
    marginTop: "30px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "30px",
    flexDirection: "column",
  },
  supportListItem: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "10px",
  },
  supportImageContainer: {
    width: "35%",
    "& img": {
      width: "100%",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      "& img": {
        width: "100%",
      },
    },
  },
  supportSystemDetails: {
    width: "50%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  blueSection: {
    background: `url(${sectionBackground})`,
    backgroundSize: "cover",
    overflow: "hidden",
    backgroundPosition: "top",
    backgroundRepeat: "no-repeat",
  },
  integrationChannelContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-around",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  imageContainer: {
    width: "40%",
    "& img": {
      width: "auto",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      "& img": {
        width: "100%",
      },
    },
  },
  integrationChannelContent: {
    width: "50%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "20px",
    marginTop: "50px",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  comparisionContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    marginTop: "60px",
  },
  comparisionTable: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    gap: "30px",
    width: "25%",
    position: "sticky",
    top: "80px",
    "@media only screen and (max-width: 600px)": {
      display: "none",
    },
  },
  comparisionTableBody: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    gap: "20px",
    width: "100%",
  },
  tableOption: {
    position: "relative",
    padding: "0 20px",
    transition: "0.3s ease",
    cursor: "pointer",
    borderLeft: "2px solid transparent",
    "&.active": {
      borderLeft: "2px solid #F27335",
    },
    "&:hover": {
      borderLeft: "2px solid #F27335",
    },
  },
  comparisionTableContent: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    gap: "30px",
    width: "75%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  comparisionTableContentContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    gap: "20px",
    width: "100%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  comparisionContainerImage: {
    padding: "20px 70px",
    background: "#def6fe",
    borderRadius: "20px",
    "& img": {
      width: "100%",
    },
    "@media only screen and (max-width: 600px)": {
      "& img": {
        width: "100% !important",
      },
    },
  },
  comparisionContainerFooter: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
  },
  readMore: {
    cursor: "pointer",
    color: "#ff7a59",
    textDecoration: "underline",
  },
  faqTitle: {
    color: "rgb(46, 63, 79)",
    display: "flex",
    fontSize: 20,
    marginTop: 10,
    fontWeight: 600,
    marginBottom: 8,
    justifyContent: "space-between",
    alignItems: "center",
  },
  faqAnswer: {
    color: "rgb(46, 63, 79)",
    fontSize: 18,
    marginTop: 8,
    marginBottom: 8,
    maxWidth: 920,
    width: "calc( 100% - 50px )",
  },
  expendButton: {
    backgroundColor: "#eaf0f6",
    "&:hover": {
      background: "#a1dae2",
    },
  },
  mobileContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: "30px",
    "& p": {
      fontSize: "10px",
      color: "#acacac",
    },
  },
  mobileSupportSystem: {
    display: "none",
    width:'100%',
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "20px",
    "@media only screen and (max-width: 600px)": {
      display: "flex",
    },
  },
}));

const singaporeCrmV2 = () => {
  const classes = useStyles();

  const keyPoints = [
    "All-in-one CRM",
    "AI-powered tool ",
    "Upto 50% PSG grant available",
    "Built for Singapore SMEs",
    "Fully integrated",
    "24*7 Local Singapore support",
  ];
  const featureList = [
    {
      title: "Auto-lead capture ",
      image: autoCaptureLeadsIcon,
    },
    {
      title: "Contact management",
      image: contactmanagementIcon,
    },
    {
      title: "Pipeline management",
      image: piplineIcon,
    },
    {
      title: "Omnichannel CRM",
      image: omniChannelIcon,
    },
    {
      title: "Email marketing",
      image: messageIcon,
    },
    {
      title: "Quotation generation",
      image: quotationIcon,
    },
    {
      title: "Sales forecasting",
      image: salesIcon,
    },
    {
      title: "Opportunity management",
      image: oppertunityIcon,
    },
    {
      title: "Workflow automation",
      image: whatsAppFlowIcon,
    },
    {
      title: "AI chatbot",
      image: aiChatIcon,
    },
    {
      title: "WhatsApp CRM",
      image: whatsappIcon,
    },
    {
      title: "Mobile CRM",
      image: mobileIcon,
    },
    {
      title: "UTM tracking",
      image: utmTrackingIcon,
    },
    {
      title: "Real-time dashboard",
      image: dashboardIcon,
    },
    {
      title: "VOIP tool integration",
      image: airCallIcon,
    },
    {
      title: "Video conferencing",
      image: videoCallIcon,
    },
  ];
  const highlights = [
    "Recognised by IMDA as a pre-approved PSG vendor.",
    "CTO-aaS provider guiding SMEs towards digitalisation.",
    "All-in-one CRM with cutting-edge features and integrations.",
    "Best affordable CRM solution designed just for SMEs.",
    "24/7 local Singapore support for immediate assistance.",
    "Complete solution for WhatsApp sales and marketing.",
    "CRM with AI integration and workflow automation.",
    "Fully customisable CRM with a user-friendly interface.",
  ];

  const benifitList = [
    {
      title: "Streamlines communication with omnichannel integration",
      image: benifitImage4,
      description:
        "Pepper Cloud is the best CRM in Singapore that streamlines sales communication across multiple channels (WhatsApp, Facebook, Instagram, and Telegram). Automate follow-ups, track interactions, assign chats, and manage conversations on one platform.",
      className: "benefit-1",
      altText: "CRM Software with Omnichannel Integration",
    },
    {
      title: " Integrates with multiple apps conveniently",
      image: integratesWithMultipleApp,
      description:
        "Pepper Cloud CRM system is a centralised ecosystem that seamlessly integrates with your favourite apps. Connect email inbox, accounting software, voice calling and video conferencing tools, messaging apps, and more to streamline your workflow.",
      className: "benefit-2",
      altText: "Singapore CRM for Small Businesses",
    },
    {
      title: "Turbocharges sales and marketing with data precision",
      image: benifitImage3,
      description:
        "Singapore's leading CRM empowers SMEs with data-driven insights and actionable strategies for maximising ROI. Analyse leads, sales, messages, activities, and marketing campaigns with visual dashboards. ",
      className: "benefit-3",
      altText: "CRM workflow automation",
    },
    {
      title: "Automates the entire sales process",
      image: benifitImage2,
      description:
        "Transform your sales process with Pepper Cloud CRM's automated workflows. Capture leads, send follow-ups, schedule meetings, summarise sales conversations, add call notes, generate quotations, and more with seamless sales automation.",
      className: "benefit-4",
      altText: "AI CRM Singapore",
    },
    {
      title: "Boosts productivity with AI-powered assistance",
      image: benifitImage1,
      description:
        "Enhance productivity with AI-powered support through Pepper Cloud CRM's AssistAI. Automate customer interactions, train chatbot to provide instant answers to queries, and streamline task management with AI automation.",
      className: "benefit-5",
      altText: "AI assistance CRM Singapore",
    },
  ];

  const supportSystemListing = [
    {
      buttomText: "Beauty salons",
      image: beautyImage,
      desc: "Beauty salons and wellness centres use Pepper Cloud, Singapore’s top CRM solution, to revolutionise business management. It smoothly handles appointment bookings through WhatsApp, Facebook, etc. and sends automatic reminders, drastically reducing no-shows.",
      points: [
        "Allow clients to schedule or reschedule appointments hassle-free via WhatsApp forms.",
        "Send personalised skincare and haircare advice based on clients’ preferences and chat history.",
        "Instantly answer product inquiries and post-treatment care with AssistAI, an AI-powered chatbot.",
      ],
      altText: "CRM Software for Beauty Salon",
    },
    {
      buttomText: "Food delivery",
      image: foodImage,
      desc: "Food delivery businesses in Singapore can significantly boost their operations with Pepper Cloud’s advanced sales CRM features. It enables automatically sending order confirmations, real-time order updates, and more.",
      points: [
        "Send personalised food suggestions via message templates on various messaging channels.",
        "Share interactive menus in WhatsApp chats to enable instant food ordering.",
        "Get instant ratings and feedback with interactive message templates.",
      ],
      altText: "CRM Software for food delivery",
    },
    {
      buttomText: "Travel agency",
      image: travelImage,
      desc: "Pepper Cloud CRM, the best Singapore CRM software for the travel industry, is revolutionising customer experiences and streamlining operations. This powerful platform enables businesses to craft personalised journeys, simplifies processes, and captivates travellers with irresistible offers.",
      points: [
        "Streamline travel bookings and package selection with interactive WhatsApp forms.",
        "Share tickets, itineraries, and property details on WhatsApp, Facebook, or other messaging channels.",
        "Send booking updates and travel reminders via pre-made templates with automated workflows.",
      ],
      altText: "CRM Software for Travel Agency",
    },
    {
      buttomText: "Real estate",
      image: supportImage,
      desc: "Real estate businesses in Singapore boost operations with Pepper Cloud CRM software. It automates lead capture, answers property queries, and schedules property tours, enabling realtors to close deals swiftly.",
      points: [
        "Capture your clients' details and preferences with interactive WhatsApp forms.",
        "Answer property-related questions in real time with the AssistAI chatbot.",
        "Share property documents, agreements, and disclosures securely via WhatsApp, Facebook Messenger, etc.",
      ],
      altText: "CRM Software for Real Estate ",
    },
  ];

  const [open, setOpen] = React.useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeTab, setActiveTab] = useState(1);
  const [supportSystemActive, setsupportSystemActive] = useState(0);

  const handleClickCollapse = (index) => () => {
    setOpen((res) => (res === index ? -1 : index));
  };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollPosition = window?.scrollY;
  //     const sectionElement = document.querySelector(".benifitSection");
  //     console.log("shshh", sectionElement);
  //     if (sectionElement) {
  //       const sectionPosition = sectionElement.offsetTop;
  //       const sectionHeight = sectionElement.offsetHeight;

  //       if (
  //         scrollPosition >= sectionPosition &&
  //         scrollPosition < sectionPosition + sectionHeight
  //       ) {
  //         const newIndex = Math.floor(
  //           (scrollPosition - sectionPosition) / (sectionHeight / 3)
  //         );
  //         setActiveIndex(newIndex);
  //       }
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const scrollToTop = (containerId) => {
    const container = document.getElementById(containerId);
    if (container) {
      const containerTop = container.getBoundingClientRect().top;
      const offsetPosition = containerTop + window.pageYOffset - 80;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <main>
      <SEO
        canonical="/best-crm-system-singapore-for-small-businesses"
        description="Elevate your small business with best CRM system in Singapore. All-in-one CRM packed with must-have features & integrations & supports up to 50% PSG grant for SMEs."
        keywords="crm system singapore,
				crm singapore,
        free crm software singapore,
        crm software for small business singapore,
        crm software singapore,
        best crm singapore"
        pathname="/best-crm-system-singapore-for-small-businesses"
        title="Best CRM System Singapore | CRM Software for Small Business"
      />
      <section
        className={classes.singaporeHomeBanner}
        aria-label="Best CRM Software Singapore"
      >
        <div className="container">
          <div className={classes.heroSection}>
            <div className={classes.leftSection}>
              <motion.h1
                className="font-48 font-extrabold text-center"
                initial={{ opacity: 0, x: -30 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                aria-label="Best CRM Software Singapore"
              >
                Crush your sales goals with the{" "}
                <span className={classes.highlight}>
                  {" "}
                  best CRM system in Singapore
                </span>
              </motion.h1>
              <motion.p
                className="font-20 text-center"
                initial={{ opacity: 0, x: -30 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                aria-label="Best CRM Software Singapore"
              >
                Unlock unparalleled sales efficiency and growth with the best
                CRM system in Singapore. The intuitive platform empowers your
                sales teams to streamline operations seamlessly and maximise
                revenue potential.
              </motion.p>

              <div
                className={classes.list}
                aria-label="Best CRM Software Singapore"
              >
                {keyPoints.map((point, index) => (
                  <motion.div
                    className={classes.listItem}
                    key={index}
                    whileInView={{ opacity: [0, 1], x: [-50, 0] }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    viewport={{ once: true }}
                    aria-label={`Feature: ${point}`}
                  >
                    <img src={greenCheckIcon} alt="Check Mark" />
                    <p className="font-20">{point}</p>
                  </motion.div>
                ))}
              </div>
              <motion.a
                href="https://peppercloud.com/contact?utm_source=crm+system+singapore&utm_medium=Book+a+free+demo"
                initial={{ opacity: 0, x: -30 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                className="primaryButton font-24 mob-w-100"
                aria-label="Best CRM Software Singapore"
              >
                Book a free demo
              </motion.a>
            </div>

            <motion.div
              className={classes.rightSection}
              initial={{ opacity: 0, x: 30 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              aria-label="Best CRM Software Singapore"
            >
              <img
                src={singaporeCrmHeroImg}
                alt="Best CRM Software Singapore"
                className={classes.img}
              />
            </motion.div>
          </div>
        </div>
      </section>
      <section
        className={classes.smeSection}
        aria-label="SME section on Pepper Cloud website, showcasing grants and benefits for small and medium enterprises"
      >
        <div className="container">
          <div className="smeSection">
            <motion.div
              className="absoluteCard"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              alt="Logos of Pepper Cloud's business partners: WhatsApp Business, Meta, and Pre-Approved Agent"
            >
              <img
                src={whatsappBusinessPartner}
                alt="WhatsApp Business Partner"
              />
              <img src={metaBusinessPartner} alt="Meta Business Partner" />
              <img src={preApprovedAgent} alt="Singapore CRM with PSG Grant" />
            </motion.div>
            <motion.p
              className="font-20"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              aria-label="Pepper Cloud Supported by IMDA"
            >
              SMEs are eligible for up to 50% Productivity Solutions Grant (PSG)
              support for the adoption of Pepper Cloud CRM solution, a
              Pre-Approved Solution under the IMDA SMEs Go Digital programme.{" "}
              <a
                className="hyperLink"
                href="https://peppercloud.com/psg-grant?utm_source=crm+system+singapore&utm_medium=webpage"
                aria-label="Pepper Cloud Supported by IMDA"
              >
                Claim your 50% PSG grant
              </a>{" "}
              .
            </motion.p>
            {/* <div className="border mt40"></div> */}
            <motion.img
              className="smeImg mt25"
              onClick={() =>
                window.open(
                  "https://peppercloud.com/psg-grant?utm_source=crm+system+singapore&utm_medium=webpage"
                )
              }
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              src={smeImg}
              alt="Pepper Cloud Supported by IMDA"
            ></motion.img>
            {/* <motion.a
              href="https://peppercloud.com/contact?utm_source=crm+system+singapore&utm_medium=Get+started"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className="primaryButton font-24 mt60 mob-w-100"
              aria-label="Pepper Cloud Supported by IMDA"
            >
              Get Started
            </motion.a> */}
          </div>
        </div>
      </section>
      <section
        className="border"
        aria-label="Benefits of using all-in-one CRM software in Singapore"
      >
        <div className="container">
          <motion.div
            className="header"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            aria-label="Heading: Benefits of using all-in-one CRM software in Singapore"
          >
            <h2 className="font-48 font-extrabold">
              Benefits of using all-in-one CRM software in Singapore
            </h2>
          </motion.div>
          <div className={clsx(classes.benifitSection, "benifitSection")}>
            <div className={classes.benifitSectionLeft}>
              {benifitList.map((content, index) => (
                <div
                  key={index}
                  className={classes.contentBox}
                  onClick={() => setActiveIndex(index)}
                >
                  <div
                    className={clsx(
                      classes.contentBoxDetails,
                      content.className,
                      activeIndex === index ? "active" : ""
                    )}
                  >
                    <img
                      src={triangleArrow}
                      className=""
                      alt={content.altText}
                    />
                    <div className={classes.contentDetails}>
                      <p
                        className="font-20 font-bold"
                        aria-label={content.altText}
                      >
                        {content.title}
                      </p>

                      <p
                        className={`font-20 smoothScroll ${
                          activeIndex === index && "active"
                        }`}
                      >
                        {content.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className={classes.benifitSectionRight}>
              {benifitList.map(
                (content, index) =>
                  activeIndex === index && (
                    <motion.div
                      initial={{ opacity: 0, x: 30 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 1 }}
                      viewport={{ once: true }}
                      aria-label={content.altText}
                    >
                      <img
                        className={classes.benifitImage}
                        src={content.image}
                        alt={content.altText}
                      />
                    </motion.div>
                  )
              )}
            </div>
          </div>
        </div>
      </section>
      <section
        className="border"
        aria-label="Pepper Cloud CRM features tailored for business success"
      >
        <div className="container">
          <motion.div
            className="header"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            aria-label="Heading: Pepper Cloud CRM features tailored for business success"
          >
            <h2 className="font-48 font-extrabold">
              Pepper Cloud CRM features tailored for business success
            </h2>
          </motion.div>
          <div className={classes.cardsContainer}>
            {featureList.map((feature, index) => (
              <motion.div
                className={classes.card}
                key={index}
                whileInView={{ opacity: [0, 1], y: [50, 0] }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
                aria-label={`Pepper Cloud CRM feature: ${feature.title}`}
              >
                <img
                  src={feature.image}
                  className={classes.cardImage}
                  alt={`Pepper Cloud CRM feature: ${feature.title}`}
                />
                <p className="font-18 font-bold">{feature.title}</p>
              </motion.div>
            ))}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <motion.a
                href="https://peppercloud.com/contact?utm_source=crm+system+singapore&utm_medium=Book+a+free+demo"
                className="primaryButton font-24 mt60 mob-w-100"
                whileInView={{ opacity: [0, 1], y: [50, 0] }}
                viewport={{ once: true }}
                transition={{ duration: 1, delay: featureList.length * 0.1 }}
                aria-label="Explore all features of Pepper Cloud CRM for business success"
              >
                Book a free demo
              </motion.a>
            </div>
          </div>
        </div>
      </section>
      <section
        className="border"
        aria-label="Best CRM System for SMEs in Singapore"
      >
        <div className="container">
          <motion.div
            className="header"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            aria-label="Best CRM System for SMEs in Singapore"
          >
            <h2 className="font-48 font-extrabold">
              Why is Pepper Cloud the best CRM system for SMEs in Singapore?
            </h2>
          </motion.div>
          <div
            className={classes.highlightSection}
            aria-label="Best CRM System for SMEs in Singapore"
          >
            {highlights.map((highlight, index) => (
              <motion.div
                className={classes.highlightCard}
                key={index}
                whileInView={{ opacity: [0, 1], y: [50, 0] }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
                aria-label="Best CRM System for SMEs in Singapore"
              >
                <img
                  className={""}
                  src={greenCheckIcon}
                  alt="Check Mark"
                />
                <p className="font-20">{highlight}</p>
              </motion.div>
            ))}
          </div>
          <motion.div
            initial={{ opacity: 0, x: -30 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className={classes.salesPipelineImageContainer}
            aria-label="Image: Pepper Cloud CRM sales pipeline"
          >
            <img
              src={salesPiplineImage}
              className={classes.salesPipeline}
              alt="Pepper Cloud CRM sales pipeline"
            />
          </motion.div>
        </div>
      </section>
      <section
        className="border"
        aria-label="Accelerate your sales journey with the top sales CRM"
      >
        <div className="container">
          <motion.div
            className="header"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            aria-label="Heading: Accelerate your sales journey with the top sales CRM"
          >
            <h2 className="font-48 font-extrabold">
              Accelerate your sales journey with the top sales CRM
            </h2>
          </motion.div>
          <div
            className={classes.statsContainer}
            aria-label="Key statistics about the benefits of using Pepper Cloud CRM"
          >
            <motion.div
              className={classes.statsBox}
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.5 }}
              viewport={{ once: true }}
              aria-label="93% more deals in your sales pipeline"
            >
              <p className="font-40 font-orange font-bold">93%</p>
              <p className="font-20 ">More deals in your sales pipeline</p>
            </motion.div>
            <motion.div
              className={classes.statsBox}
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 1 }}
              viewport={{ once: true }}
              aria-label="46% less deal closing time"
            >
              <p className="font-40 font-orange font-bold">46%</p>
              <p className="font-20 ">Less deal closing time</p>
            </motion.div>
            <motion.div
              className={classes.statsBox}
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 1.5 }}
              viewport={{ once: true }}
              aria-label="21% rise in average deal value"
            >
              <p className="font-40 font-orange font-bold">21%</p>
              <p className="font-20 ">Rise in average deal value </p>
            </motion.div>
          </div>
          <div className={classes.mobileContent}>
            <p>*Statistics from multiple sources.</p>
          </div>
        </div>
      </section>
      <section
        className="border"
        aria-label="Best CRM System for SMEs in Singapore"
      >
        <div className="container">
          <motion.div
            className="header"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            aria-label="Best CRM System for SMEs in Singapore"
          >
            <h2
              className="font-48 font-extrabold"
              aria-label="Best CRM System for SMEs in Singapore"
            >
              CRM system Singapore: <br /> Built to support multiple industries
            </h2>
          </motion.div>
          <div
            className={classes.supportSystem}
            aria-label="Industry-specific CRM solutions from Pepper Cloud"
          >
            <div
              className={classes.supportToggler}
              aria-label="Industry-specific CRM solutions from Pepper Cloud"
            >
              <div
                className={clsx(
                  "font-24",
                  "font-bold",
                  supportSystemActive === 0 ? "active" : "",
                  classes.toggler
                )}
                onClick={() => setsupportSystemActive(0)}
                aria-label="CRM Software for Beauty Salon"
              >
                Beauty salons
              </div>

              <div
                className={clsx(
                  "font-24",
                  "font-bold",
                  supportSystemActive === 1 ? "active" : "",
                  classes.toggler
                )}
                onClick={() => setsupportSystemActive(1)}
                aria-label="CRM Software for food delivery"
              >
                Food delivery
              </div>

              <div
                className={clsx(
                  "font-24",
                  "font-bold",
                  supportSystemActive === 2 ? "active" : "",
                  classes.toggler
                )}
                onClick={() => setsupportSystemActive(2)}
                aria-label="CRM Software for Travel Agency"
              >
                Travel agency
              </div>

              <div
                className={clsx(
                  "font-24",
                  "font-bold",
                  supportSystemActive === 3 ? "active" : "",
                  classes.toggler
                )}
                onClick={() => setsupportSystemActive(3)}
                aria-label="CRM Software for Real Estate"
              >
                Real Estate
              </div>
            </div>
          </div>

          <div className={clsx(classes.supportSystemContainer, "mob-hide")}>
            {supportSystemListing.map(
              (support, index) =>
                index === supportSystemActive && (
                  <motion.div
                    className={classes.supportImageContainer}
                    initial={{ opacity: 0, x: -30 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    viewport={{ once: true }}
                    // aria-label={support.altText}
                  >
                    <img src={support.image} alt={support.altText} />
                  </motion.div>
                )
            )}
            {supportSystemListing.map(
              (listingItem, index) =>
                supportSystemActive === index && (
                  <motion.div
                    className={classes.supportSystemDetails}
                    initial={{ opacity: 0, x: 30 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    viewport={{ once: true }}
                    key={index}
                    id={index}
                    aria-label={listingItem.altText}
                  >
                    <p className="font-18" aria-label={listingItem.altText}>
                      {listingItem.desc}
                    </p>
                    <div
                      className={classes.supportList}
                      aria-label="Key features of Pepper Cloud CRM for real estate businesses in Singapore"
                    >
                      {listingItem.points.map((point, index) => (
                        <div
                          className={classes.supportListItem}
                          aria-label="Feature: Interactive WhatsApp forms for property discovery"
                        >
                          <img
                            src={greenCheckOutline}
                            alt={listingItem.altText}
                          />
                          <p className="font-18">{point}</p>
                        </div>
                      ))}
                    </div>
                    {index === 3 && (
                      <a
                        href="https://peppercloud.com/crm-case-studies?utm_source=crm+system+singapore&utm_medium=Read+customer+stories"
                        className="primaryButton font-24 mt40"
                        aria-label="Read customer stories about Pepper Cloud CRM for real estate businesses in Singapore"
                      >
                        Read customer stories
                      </a>
                    )}
                  </motion.div>
                )
            )}
          </div>
          <div className={clsx(classes.supportSystemContainer, "mob-visible")}>
            {supportSystemListing.map((listingItem, index) => (
              <div className={classes.mobileSupportSystem}>
                <div
                  className={clsx(
                    "font-bold",
                    supportSystemActive === index ? "active" : "",
                    classes.mobileToggler
                  )}
                  onClick={() => setsupportSystemActive(index)}
                >
                  {listingItem.buttomText}
                </div>
                {supportSystemActive === index && (
                  <div className={classes.supportSystemMobileContent}>
                    <motion.div
                      className={classes.supportImageContainer}
                      initial={{ opacity: 0, x: -30 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 1 }}
                      viewport={{ once: true }}
                      // aria-label={support.altText}
                    >
                      <img src={listingItem.image} alt={listingItem.altText} />
                    </motion.div>
                    <motion.div
                      className={classes.supportSystemDetails}
                      initial={{ opacity: 0, x: 30 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 1 }}
                      viewport={{ once: true }}
                      key={index}
                      id={index}
                      aria-label={listingItem.altText}
                    >
                      <p className="font-18" aria-label={listingItem.altText}>
                        {listingItem.desc}
                      </p>
                      <div
                        className={classes.supportList}
                        aria-label="Key features of Pepper Cloud CRM for real estate businesses in Singapore"
                      >
                        {listingItem.points.map((point, index) => (
                          <div
                            className={classes.supportListItem}
                            aria-label="Feature: Interactive WhatsApp forms for property discovery"
                          >
                            <img
                              src={greenCheckOutline}
                              alt={listingItem.altText}
                            />
                            <p className="font-18">{point}</p>
                          </div>
                        ))}
                      </div>
                      {index === 3 && (
                        <a
                          href="https://peppercloud.com/crm-case-studies?utm_source=crm+system+singapore&utm_medium=Read+customer+stories"
                          className="primaryButton font-24 mt40 mob-w-100"
                          aria-label="Read customer stories about Pepper Cloud CRM for real estate businesses in Singapore"
                        >
                          Read customer stories
                        </a>
                      )}
                    </motion.div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
      <section
        className={classes.blueSection}
        aria-label="Pepper Cloud CRM system with seamless integrations"
      >
        <div className="container">
          <div className={classes.integrationChannelContainer}>
            <motion.div
              className={classes.imageContainer}
              initial={{ opacity: 0, x: -30 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              aria-label="Singapore CRM Software Integration "
            >
              <img
                src={integrationChannels}
                alt="Singapore CRM Software Integration "
              />
            </motion.div>
            <motion.div
              className={classes.integrationChannelContent}
              initial={{ opacity: 0, x: 30 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              aria-label="Singapore CRM Software Integration"
            >
              <p
                className="font-48 font-bold font-white text-center"
                aria-label="Singapore CRM Software Integration"
              >
                An all-integrated CRM system in Singapore
              </p>
              <p className="font-22 font-white mt40 text-center">
                Elevate your business with exclusive plug-and-play integrations.
                Connect effortlessly with Mailchimp, Gmail, WhatsApp, Facebook,
                Xero, Aircall, Zoom, and more, empowering your team with an
                all-in-one CRM system. Explore our extensive integration library
                to unlock all the features, enhancing your efficiency and
                performance.
              </p>
              <div className={classes.buttonContainer}>
                <a
                  href="https://peppercloud.com/integration?utm_source=crm+system+singapore&utm_medium=Explore+integrations"
                  className="secondaryButtonOutline font-20 font-bold mob-w-100"
                  aria-label="Singapore CRM Software Integration"
                >
                  Explore integrations
                </a>

                <a
                  href="https://peppercloud.com/contact?utm_source=crm+system+singapore&utm_medium=Book+a+free+demo"
                  className="secondaryButton font-20 font-bold mob-w-100"
                  aria-label="Singapore CRM Software Integration"
                >
                  Book a free demo
                </a>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <motion.div
            className="header"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            <h2 className="font-48 font-extrabold">
              Top 5 CRM systems in Singapore in 2024
            </h2>
          </motion.div>
          <div className={classes.comparisionContainer}>
            <div className={classes.comparisionTable}>
              <div
                className={clsx(
                  classes.comparisionTableHeader,
                  "font-22",
                  "font-bold"
                )}
              >
                Table of Content
              </div>
              <div className={classes.comparisionTableBody}>
                <div
                  className={clsx(
                    classes.tableOption,
                    "font-22",
                    activeTab === 1 ? "active" : ""
                  )}
                  onClick={() => {
                    scrollToTop("container1");
                    setActiveTab(1);
                  }}
                >
                  1. Pepper Cloud
                </div>
                <div
                  className={clsx(
                    classes.tableOption,
                    "font-22",
                    activeTab === 2 ? "active" : ""
                  )}
                  onClick={() => {
                    scrollToTop("container2");
                    setActiveTab(2);
                  }}
                >
                  2. Star CRM
                </div>
                <div
                  className={clsx(
                    classes.tableOption,
                    "font-22",
                    activeTab === 3 ? "active" : ""
                  )}
                  onClick={() => {
                    scrollToTop("container3");
                    setActiveTab(3);
                  }}
                >
                  3. Sage CRM
                </div>
                <div
                  className={clsx(
                    classes.tableOption,
                    "font-22",
                    activeTab === 4 ? "active" : ""
                  )}
                  onClick={() => {
                    scrollToTop("container4");
                    setActiveTab(4);
                  }}
                >
                  4. Tigernix CRM
                </div>
                <div
                  className={clsx(
                    classes.tableOption,
                    "font-22",
                    activeTab === 5 ? "active" : ""
                  )}
                  onClick={() => {
                    scrollToTop("container5");
                    setActiveTab(5);
                  }}
                >
                  5. Flavor CRM
                </div>
              </div>
            </div>
            <div className={classes.comparisionTableContent}>
              <div
                className={classes.comparisionTableContentContainer}
                id="container1"
              >
                <motion.h3
                  className="font-18 font-bold"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                  aria-label="Pepper Cloud - Best CRM Software for Singapore SMEs"
                >
                  1. Pepper Cloud CRM
                </motion.h3>

                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  Pepper Cloud is the best CRM system in Singapore for SMEs. It
                  streamlines sales, marketing, and support with WhatsApp
                  integration, AI chatbots, omnichannel capabilities, and more.
                </motion.p>

                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">All-in-one sales CRM:</b> Pepper
                  Cloud CRM provides growing businesses with a unified solution
                  to boost sales operations, manage leads and opportunities, and
                  streamline communications. This all-in-one platform integrates
                  seamlessly with messaging channels like Facebook and WhatsApp,
                  email marketing tools like MailerLite and Mailchimp, voice
                  calling tools like Aircall, video calling tools like Zoom,
                  etc.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Workflow automation:</b> Pepper Cloud
                  CRM boosts productivity by automating workflows with specific
                  triggers and conditions. Automatically handle repetitive tasks
                  like assigning tickets, sending emails, and more, allowing
                  your sales team to concentrate on more complex activities.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">WhatsApp CRM:</b> Pepper Cloud is the
                  best sales CRM with WhatsApp integration. This CRM integration
                  allows you to send promotional messages to multiple contacts,
                  reply swiftly using message templates, share product catalogs
                  for instant purchases, and much more.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Sales pipeline management:</b>{" "}
                  Simplify and organise your sales pipeline with robust sales
                  management capabilities of Pepper Cloud CRM. Create multiple
                  sales pipelines and stages, visualise pipeline for enhanced
                  opportunity visibility and forecast sales accurately. With an
                  intuitive interface and live insights, effortlessly monitor
                  sales activities to boost team productivity.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Lead management:</b> Accelerate the
                  sales process by automating lead capture from website, ads,
                  and incoming messages. Eliminate data entry, auto-assign the
                  leads to the team members, and ensure no leads go unattended.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">UTM tracking:</b> With the robust UTM
                  tracking feature, automatically monitor lead sources and key
                  marketing campaign details. This comprehensive data is
                  essential for analysing campaign performance and making
                  informed, data-driven decisions that enhance your marketing
                  strategy.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  Pepper Cloud CRM, the best sales CRM system in Singapore,
                  offers many other features like account management, AI
                  capabilities, insights and analytics, and more for small
                  businesses to operate efficiently and growing businesses to
                  scale quickly.
                </motion.p>

                <div className={classes.comparisionContainerFooter}>
                  <motion.a
                    href="https://peppercloud.com/contact?utm_source=crm+system+singapore&utm_medium=Book+a+free+demo"
                    className="primaryButton font-24 mob-w-100"
                    whileInView={{ opacity: [0, 1], y: [50, 0] }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.3 }}
                  >
                    Book a free demo
                  </motion.a>

                  {/* <a
                  href="/"
                  className={clsx(
                    classes.readMore,
                    "font-18 font-bold font-orange"
                  )}
                >
                  Read more
                </a> */}
                </div>
                <motion.div
                  className={classes.comparisionContainerImage}
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <img
                    // style={{ width: "auto" }}
                    src={pcHomepage}
                    alt="Pepper Cloud - Best CRM Software for Singapore SMEs"
                  />
                </motion.div>
              </div>
              <div
                className={classes.comparisionTableContentContainer}
                id="container2"
              >
                <motion.h3
                  className="font-18 font-bold"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                  aria-label="Star CRM"
                >
                  2. Star CRM
                </motion.h3>

                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  Star CRM provides comprehensive CRM software for businesses in
                  the Asia-Pacific. The tool manages customer data,
                  interactions, and relationships across channels.
                </motion.p>

                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Loyalty management: </b>Boosts
                  customer retention with personalised loyalty programmes and
                  rewards.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Data and analytics: </b>Leverages
                  comprehensive data and analytics to gain actionable insights
                  and drive business growth.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Telemarketing: </b>Enhances customer
                  engagement and sales through targeted telemarketing campaigns.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Digital marketing: </b>Maximises your
                  online presence and reach with strategic digital marketing
                  solutions.
                </motion.p>
                <motion.div
                  className={classes.comparisionContainerImage}
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <img src={starCrmImage} alt="Star CRM" />
                </motion.div>
              </div>
              <div
                className={classes.comparisionTableContentContainer}
                id="container3"
              >
                <motion.h3
                  className="font-18 font-bold"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                  aria-label="Sage CRM"
                >
                  3. Sage CRM
                </motion.h3>

                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  Sage CRM is a cloud-based CRM platform designed for small and
                  midsized businesses. It optimises the customer experience with
                  sales and marketing automation, customer service, a help desk,
                  social CRM, and mobile capabilities.
                </motion.p>

                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Contact management: </b>Keeps track
                  of customer details and interactions for better relationship
                  management.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Customer service management: </b>
                  Enhances customer satisfaction with efficient issue tracking
                  and resolution.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Lead management: </b>Enables users to
                  capture, track, and nurture leads to convert them into loyal
                  customers.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Quote and order management: </b>
                  Simplifies the process of generating quotes and managing
                  orders for smooth sales operations.
                </motion.p>
                <motion.div
                  className={classes.comparisionContainerImage}
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <img src={sageCrmImage} alt="Sage CRM" />
                </motion.div>
              </div>
              <div
                className={classes.comparisionTableContentContainer}
                id="container4"
              >
                <motion.h3
                  className="font-18 font-bold"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                  aria-label="Tigernix "
                >
                  4. Tigernix CRM
                </motion.h3>

                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  Tigernix offers software solutions for different technology
                  platforms and operating systems. The company has a presence in
                  Indonesia and Australia and provides software development
                  services. Tigernix operates across various infrastructures to
                  serve its customers.
                </motion.p>

                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Single platform management:</b>{" "}
                  Manages company’s internal processes from a single platform.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Customisable software: </b>The CRM
                  can be tailored to meet specific business needs.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Call centre support: </b>Includes
                  call centre support for customer interactions.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Cost reduction: </b>Reduces costs by
                  centralising data management and eliminating the need for
                  multiple software.
                </motion.p>
                <motion.div
                  className={classes.comparisionContainerImage}
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <img src={tigernixCrmImage} alt="Tigernix " />
                </motion.div>
              </div>
              <div
                className={classes.comparisionTableContentContainer}
                id="container5"
              >
                <motion.h3
                  className="font-18 font-bold"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                  aria-label="Flavor CRM"
                >
                  5. Flavor CRM
                </motion.h3>

                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  Flavor CRM enables lead tracking, client management, and deal
                  closing. To begin using the system, users need to grant access
                  to their HR database, after which Flavor CRM will import and
                  organise the information.
                </motion.p>

                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Customer support: </b>Provides
                  support with automated ticketing, quick responses, and
                  efficient issue resolution.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Marketing automation: </b>Streamlines
                  marketing efforts with automated campaigns, personalised
                  messaging, and performance tracking.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Lead scoring: </b>Prioritises sales
                  efforts by ranking leads based on their engagement and
                  potential value.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Contact management: </b>Organises and
                  manages contacts, ensuring all customer information is
                  up-to-date and accessible.
                </motion.p>
                <motion.div
                  className={classes.comparisionContainerImage}
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <img src={flavorCrmImage} alt="Flavor CRM" />
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="border">
        <div className="container">
          <motion.div
            className="header"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            <h2 className="font-40 font-extrabold">
              Best CRM software in Singapore - Trusted by many businesses
            </h2>
          </motion.div>
          <div className="mt40">
            <CompanyCard className={classes.cimg} />
          </div>
        </div>
      </section>
      <section className={clsx(classes.blueSection, "border")}>
        <div className="container">
          <motion.div
            className="header mb60"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            <h2 className="font-40 font-extrabold font-white">
              Fuel your business in Singapore with{" "}
              <span class="font-extrabold">Pepper Cloud CRM</span>
            </h2>
          </motion.div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <motion.a
              href="/contact?utm_source=crm+system+singapore&utm_medium=Get+started"
              className="secondaryButton font-24 mob-w-100"
              whileInView={{ opacity: [0, 1], y: [50, 0] }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              Get started
            </motion.a>
          </div>
        </div>
      </section>
      <section className="border">
        <div className="container">
          <motion.div
            className="header mb60"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            aria-label="Heading: CRM system Singapore: Built to support multiple industries"
          >
            <h2 className="font-48 font-extrabold">
              Frequently asked questions - CRM system Singapore
            </h2>
          </motion.div>
          {[
            {
              title: "What is CRM software?",
              ans: (
                <>
                  CRM software is a powerful tool that centralises customer data
                  and interactions, enabling businesses to manage relationships
                  more effectively. It streamlines sales, marketing, and
                  customer service processes by providing a unified platform for
                  tracking leads, automating tasks, and analysing customer
                  behaviour.
                </>
              ),
            },
            {
              title:
                "What features should businesses look for in a CRM system in Singapore?",
              ans: (
                <>
                  Businesses in Singapore should look for CRM features such as
                  sales pipeline management, customer support automation,
                  marketing automation, lead scoring, contact management, and
                  integration with popular tools like WhatsApp and Facebook.
                  Additionally, AI-powered tools and workflow automation are
                  essential for enhancing productivity and efficiency.
                </>
              ),
            },

            {
              title:
                "Are there any Singapore-specific regulations that CRM systems need to comply with?",
              ans: (
                <>
                  Yes, CRM systems in Singapore need to comply with the Personal
                  Data Protection Act (PDPA) to ensure customer data privacy and
                  security. Read more about{" "}
                  <a href="https://blog.peppercloud.com/data-privacy-compliance-user-guide/?utm_source=crm+system+singapore&utm_medium=Blog">
                    data privacy compliance
                  </a>
                  .
                </>
              ),
            },
            {
              title:
                "Is Pepper Cloud CRM eligible for PSG grants in Singapore? If yes, how much grant can be availed of?",
              ans: (
                <>
                  Yes, Pepper Cloud CRM is eligible for the Productivity
                  Solutions Grant (PSG) in Singapore. Businesses can avail of up
                  to 50% funding support under this grant. Know more about{" "}
                  <a href="https://blog.peppercloud.com/ultimate-guide-to-productivity-solutions-grant/?utm_source=crm+system+singapore&utm_medium=Blog">
                    PSG grant
                  </a>
                  .
                </>
              ),
            },
            {
              title:
                "What is the process to apply for the PSG grant for Pepper Cloud CRM?",
              ans: (
                <React.Fragment>
                  To apply for the PSG grant for Pepper Cloud CRM, businesses
                  need to:
                  <ol>
                    <li>Visit the Business Grants Portal.</li>
                    <li>Log in with your CorpPass account.</li>
                    <li>
                      Submit an application with the required documents,
                      including a quote from Pepper Cloud CRM.
                    </li>
                  </ol>
                  For more details,{" "}
                  <a href="https://peppercloud.com/contact?utm_source=crm+system+singapore&utm_medium=Webpage">
                    contact us
                  </a>
                  .
                </React.Fragment>
              ),
            },
            {
              title: "Who is eligible for CTOaaS in Singapore?",
              ans: (
                <>
                  Small and medium-sized enterprises (SMEs) in Singapore that
                  are looking to enhance their digital capabilities are eligible
                  for CTO-as-a-Service (CTOaaS). Know more about{" "}
                  <a href="https://peppercloud.com/cto-as-a-service?utm_source=crm+system+singapore&utm_medium=Blog">
                    {" "}
                    CTOaaS{" "}
                  </a>
                </>
              ),
            },
            {
              title: "Is CTO-as-a-Service in Singapore free?",
              ans: "No, CTO-as-a-Service (CTOaaS) in Singapore is not free, but it is heavily subsidised by government grants to make it affordable for SMEs.",
            },
            {
              title: "How do I choose the best CRM software in Singapore?",
              ans: "To choose the best CRM software in Singapore, consider factors such as ease of use, feature set, integration capabilities, customer support, scalability, and compliance with local regulations like the PDPA. Interestingly, all these points are covered by Pepper Cloud CRM, making it the best sales CRM system in Singapore.",
            },
            {
              title: "What industries can use Pepper Cloud CRM?",
              ans: "Pepper Cloud CRM is versatile and can be used by various industries, including real estate, beauty and wellness, food delivery, tours and travel, and more.",
            },
            {
              title: "Can Pepper Cloud CRM help with lead management?",
              ans: "Yes, Pepper Cloud CRM offers robust lead management features that help businesses capture, track, and nurture leads effectively",
            },
            {
              title:
                "What sales automation features does Pepper Cloud CRM offer?",
              ans: "Pepper Cloud CRM provides sales automation features like automated lead capture, follow-ups, meeting scheduling, and reporting to streamline the sales process and enhance team productivity.",
            },
            {
              title:
                "Is Pepper Cloud CRM suitable for small businesses in Singapore?",
              ans: "Absolutely, Pepper Cloud CRM is designed to cater to the needs of small businesses in Singapore, offering affordable pricing and features tailored for SMEs.",
            },
            {
              title: "How do I connect with Pepper Cloud CRM?",
              ans: (
                <>
                  To connect with Pepper Cloud CRM, visit the "
                  <a href="https://peppercloud.com/contact?utm_source=crm+system+singapore&utm_medium=Webpage">
                    Contact us
                  </a>
                  " page on our website or reach out via email or phone
                  provided.
                </>
              ),
            },
            {
              title: "What is the pricing structure for Pepper Cloud CRM?",
              ans: (
                <>
                  The pricing structure for Pepper Cloud CRM is based on the
                  size of your organisation and the services you choose to have
                  in your CRM system. For detailed pricing information,{" "}
                  <a href="https://peppercloud.com/contact?utm_source=crm+system+singapore&utm_medium=Webpage">
                    contact us
                  </a>
                  .
                </>
              ),
            },
            {
              title: "How secure is customer data in Pepper Cloud CRM?",
              ans: "Customer data in Pepper Cloud, the best CRM software in Singapore, is highly secure and complies with Singapore's PDPA regulations, ensuring data privacy and protection.",
            },
            {
              title: "How easy is it to set up Pepper Cloud CRM in Singapore?",
              ans: (
                <>
                  Pepper Cloud CRM is designed for easy setup. You can{" "}
                  <a href="https://peppercloud.com/contact?utm_source=crm+system+singapore&utm_medium=Webpage">
                    contact our support team
                  </a>{" "}
                  to get assistance with setting up the CRM for your business.
                </>
              ),
            },
          ].map((each, index) => (
            <div key={each.title}>
              <Box
                className={classes.faqTitle}
                m={0}
                onClick={handleClickCollapse(index)}
              >
                <Box component="h3" fontSize={18} my={2}>
                  {each.title}
                </Box>
                <div>
                  <IconButton
                    aria-label="Expand or Collapse"
                    className={classes.expendButton}
                    color="default"
                  >
                    {index !== open ? (
                      <AddIcon fontSize="small" />
                    ) : (
                      <RemoveIcon fontSize="small" />
                    )}
                  </IconButton>
                </div>
              </Box>
              <Collapse in={open === index} timeout="auto" unmountOnExit>
                <p className={classes.faqAnswer} fontSize={16}>
                  {each.ans}
                </p>
              </Collapse>
              <Divider />
            </div>
          ))}
        </div>
      </section>
    </main>
  );
};

export default singaporeCrmV2;
